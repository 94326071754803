import './react-leaflet.css';
import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { MapContainer, Marker, Popup, useMapEvent } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import { ReportDetails } from "./ReportDetails";
import DisplayedList from './DisplayedList';
import ParkIcon from '../../../asserts/tree.svg'
import IrrigationIcon from '../../../asserts/drop.svg'
import PaintIcon from '../../../asserts/paint.svg'
import CutIcon from '../../../asserts/cut.svg'
import RoadIcon from '../../../asserts/road.svg'
import LightIcon from '../../../asserts/light.svg'
import TrashIcon from '../../../asserts/trash.svg'
import SweepIcon from '../../../asserts/sweep.svg'
import Lotes from '../../../asserts/lotes.svg'
import AltoIcon from '../../../asserts/alto.svg'
import GobiernoIcon from '../../../asserts/gobierno.svg'
import UrbanoIcon from '../../../asserts/urbanito.svg'
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { prMain } from '../../../themeConfig';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { DatePicker, Button, Select, Input, Typography, Row, Col, Tooltip, Modal, Radio, Space, Form, Menu, Dropdown } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES';
import { AuditOutlined, CheckCircleOutlined, CloseCircleOutlined, DownOutlined, FieldTimeOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { SwitchMap } from './SwitchMap';
import { openMessage } from '../Utils/MessageDisplay';
import { useSelector } from 'react-redux';
import { getPerformance, trace } from 'firebase/performance';
import { apiGetUsersByRole, apiReportsById, apiReportsFilter, apiReportsFilterPagination, apiReportType } from '../../../utils/api';
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';

const { Option } = Select;
const { RangePicker } = DatePicker;
const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY

const classes = {
  container: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 20,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  button: {
    width: '17%',
    backgroundColor: 'white',
    color: 'inherit',
    // marginRight: '30px',
    marginTop: '10px',
    marginBottom: '5px',
    position: 'relative',
    textTransform: 'capitalize',

  },
  button2: {
    width: '200px',
    backgroundColor: 'white',
    color: 'inherit',
    marginTop: '10px',
    marginBottom: '5px',
    position: 'relative',
    left: '5%',
    textTransform: 'capitalize',

  },
  searchField: {
    height: '40px',
    width: '175px',
    backgroundColor: '#fff',
    borderRadius: '20px 0 0 20px',
  },
  dateField: {
    borderRadius: '20px',
    // backgroundColor: '#fff',
    border: '0px',
    outline: 'none',
    height: 40,
    width: '100%',
  },
  inputField: {
    border: '0px',
    height: '40px',
    width: '200px',
    paddingLeft: '20px',
    paddingRight: '10px',
    borderRadius: '20px',
  },
  inputDate: {
    border: '0px',
    marginLeft: '20px',
    height: '40px',
    width: '40%',
    paddingRight: '10px',
    fontFamily: 'Arial',
  },
  mapContainer: {
    width: '90%',
  },
  gridContainer: {
    width: '100%',
  },
  dialogTitle: {
    color: prMain,
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '20px'
  },
  buttons: {
    width: '75%',
    margin: '0 auto 20px auto',
  },
  buttonDialog: {
    width: '100%',
    marginTop: 20,
  },
  twoButtons: {
    width: '50%',
    margin: '15px',
    marginBottom: '0px',
    textTransform: 'capitalize',
  },
  select: {
    height: 40,
    width: '90%',
    border: '0px',
    borderRadius: 20,
    backgroundColor: '#fff',
  },
  inputlabel: {
    // verticalAlign: 'center'  
    marginTop: '-7px',
  },

}

export const CREATE_BY = 'Creado por'
export const ASSIGNED_BY = 'Asignado por'
export const IN_CHARGE = 'Encargado'

const DraggableMarker = props => {
  const { position, setPosition, dataRow, setRefresh } = props;
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )
  var color;

  if (dataRow.expired && dataRow.status.id !== 4) {
    color = '#999'
  } else {

    switch (dataRow.status.id) {
      case 1:
        color = '#ff4949';
        break;
      case 2:
        color = '#ffc700';
        break;
      case 3:
        color = '#ff9800';
        break;
      case 4:
        color = '#71cf71';
        break;
      default:
        color = '#fff';
        break;
    }
  }

  var ico;
  switch (dataRow.servicesDto.id) {
    case 1:
      // ico = ParkIcon;
      ico = SweepIcon;
      break;
    case 2:
      ico = IrrigationIcon;
      break;
    case 3:
      ico = PaintIcon;
      break;
    case 4:
      // ico = CutIcon;
      ico = ParkIcon;
      break;
    case 5:
      ico = CutIcon;
      // ico = RoadIcon;
      break;
    case 6:
      ico = LightIcon;
      break;
    case 7:
      ico = TrashIcon;
      break;
    case 8:
      ico = RoadIcon;
      // ico = SweepIcon;
      break;
    case 9:
      ico = Lotes;
      // ico = SweepIcon;
      break;
    case 10:
      ico = UrbanoIcon;
      break;
    case 11:
      ico = AltoIcon;
      break;
    case 12:
      ico = GobiernoIcon;
      break;
    default:
      ico = SweepIcon;
      break;

  }

  var svgBck = `<svg height="70" width="70"  xmlns='http://www.w3.org/2000/svg'> <circle cx='35' cy='35' r='30' fill='${color}' > </circle></svg>`
  var urlBck = encodeURI("data:image/svg+xml," + svgBck).replace('#', '%23');

  var mark = L.icon({
    iconUrl: ico,
    iconSize: [22, 22],
    iconAnchor: [11, 11],
    shadowUrl: urlBck,
    shadowSize: [28, 28],
    shadowAnchor: [14, 14]

  });

  const [openDetails, setOpenDetails] = useState(false);

  const handleCloseDetails = (typeNotification) => {
    props.getDataReport()
    setOpenDetails(false);
  }

  return (

    <div>

      <Marker
        draggable={false}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={mark}
      >
        <Popup minWidth={90}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

            <p>Reporte de {dataRow.servicesDto.name} con folio {dataRow.id}</p>
            <span onClick={() => setOpenDetails(true)} style={{ cursor: 'pointer', color: prMain, marginTop: -15 }}>Ver detalles</span>

          </div>
        </Popup>
      </Marker>

      {
        openDetails &&
        <Modal
          visible={openDetails}
          onCancel={handleCloseDetails}
          footer={null}
          closable={true}
          closeIcon={
            <CloseCircleOutlined onClick={() => handleCloseDetails(0)} style={{ color: 'red' }} />
          }
          centered
          title={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {dataRow.length !== 0 &&
                <Typography style={classes.dialogTitle} variant="h5">
                  {dataRow.status.id === 1 && 'Pendiente'}
                  {dataRow.status.id === 2 && 'En proceso'}
                  {dataRow.status.id === 3 && 'En revisión'}
                  {dataRow.status.id === 4 && 'Solucionado'}
                  {(!dataRow.reasonForDelete && !dataRow.deletedAt) ? "" : " (Solicitado para completar)"}
                </Typography>}
            </div>
          }
        >
          <ReportDetails refreshReports={props.refreshReports} setRefresh={setRefresh} getDataReport={props.getDataReport} data={dataRow} onCloseDetails={handleCloseDetails} setChangeStatus={props.setChangeStatus} changeStatus={props.changeStatus} />

        </Modal>
      }

    </div>
  )
};
const SetViewOnClick = ({ animateRef }) => {

  const map = useMapEvent('click', (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    })
  })

  return null
}

const DisplayedMap = () => {

  const history = useHistory();
  const perf = getPerformance()
  const { update } = useSelector(state => state.notifications);
  const { user } = useSelector(state => state.session);
  const [reports, setReports] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const current = new Date();
  const defaultTo = new Date(current)
  const defaultFrom = new Date('1/1/2022')
  const defaultFromDate = defaultFrom
  // const defaultFromDate = defaultFrom.getFullYear() + "/" + (defaultFrom.getMonth() + 1) + "/" + defaultFrom.getDate();
  const defaultToDate = defaultTo.getFullYear() + "/" + (defaultTo.getMonth() + 1) + "/" + defaultTo.getDate();

  const { idStatus, created } = useParams()
  const idStatusInt = parseInt(idStatus)

  const [statusReport, setStatusReport] = useState(5)
  const [toDate, setToDate] = useState(defaultToDate);
  const [fromDate, setFromDate] = useState(defaultFrom);
  const [changeStatus, setChangeStatus] = useState(false);
  const [status, setStatus] = useState((idStatusInt && idStatusInt > 0 && idStatusInt < 5) ? idStatusInt : 1);
  const [checked, setChecked] = useState(false);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportKey, setReportKey] = useState('');
  const [loadingReports, setloadingReports] = useState(false)
  const [userOption, setuserOption] = useState(CREATE_BY)

  const [typeName, setTypeName] = useState(user.service ? user.service.map(i => (JSON.stringify({ idType: i.id, name: i.name }))) : []);
  const [userName, setUserName] = useState([]);

  const [isFilter, setisFilter] = useState(false)
  const [page, setpage] = useState(1)
  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({ key: undefined, order: undefined })
  const [totalData, settotalData] = useState(0)

  const [refreshExcel, setrefreshExcel] = useState(false)
  const [owner, setowner] = useState('')


  const handleUserChange = (e) => {
    setowner(e.target.value)
  }

  const handleExpiredChange = (e) => {
    setpage(1)
    setStatusReport(e.target.value)
  }

  const handleChange = (value) => {
    setChecked(value);
  }

  const handleChangeStatus = (statusId) => {
    setpage(1)
    setloadingReports(true)
    setStatus(statusId);
    setChangeStatus(!changeStatus);

    history.push(`/reportes/${statusId}`)
  }


  const [position, setPosition] = useState({ lat: 28.1833, lng: -105.467 })
  const [zoom] = useState(14);

  const [dataUsers, setDataUsers] = useState([]);

  const [dataType, setDataType] = useState(user.service ? user.service.map(i => ({ idType: i.id })) : []);

  const handleChangeUser = (event) => {
    const data = []
    event.forEach(item => {
      data.push({
        idUser: JSON.parse(item).id
      })
    })

    setUserName(event)
    setDataUsers(data);
  };

  const handleChangeType = (event) => {

    const data = []
    event.forEach(item => {
      data.push({
        idType: JSON.parse(item).idType,
        name: item.name
      })
    })

    setTypeName(event)
    setDataType(data);

  };

  const handleChangeKey = (event) => {
    let value = event.target.value;
    if (!value) handleClear()

    if (!containsOnlyNumbers(value)) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
      setReportKey(event.target.value)
    } else {
      setReportKey(value)
      // setRefresh(!refresh)
    }
  }

  function containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }


  const handleClear = () => {
    setpage(1)
    setisFilter(false)
    setDataType(user.service ? user.service.map(i => ({ idType: i.id })) : [])
    setTypeName(user.service ? user.service.map(i => (JSON.stringify({ idType: i.id, name: i.name }))) : [])
    setUserName([]);
    setDataUsers([]);
    setReportKey('');

    setowner('')
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
    setStatus(1)
    history.push('/reportes')
    setStatusReport(5)
    setRefresh(!refresh)
    setuserOption(CREATE_BY)
    setrefreshExcel(prev => !prev)
    // document.getElementById("fromDateInput").value = null;
    // document.getElementById("toDateInput").value = null;

    // setChangeStatus(!changeStatus)
  }

  const handleFilter = (fromOptional, toOptionational) => {
    setpage(1)
    if (fromOptional && toOptionational) {
      getDataReport(null, null, fromOptional, toOptionational, statusReport, 1)
    } else {
      getDataReport(null, null, null, null, statusReport, 1)
    }

    setrefreshExcel(prev => !prev)
  }

  const handleSearch = (e) => {
    setpage(1)
    apiReportsById(reportKey).then(res => {
      // res.data = res.data.reports
      setReports(res.data)
      if (res.data.length > 0) {
        setStatus(0)
      }
    }).catch(err => {
      new Error(err)
    })
  }

  const getDataReport = useCallback((find, statusOptional, fromOptional, toOptional, expiredOptional, pageOpt) => {

    console.log(expiredOptional)
    const t = trace(perf, 'get_data_reports');
    t.start()
    setloadingReports(true)
    // let from = new Date(fromOptional ? fromOptional : isFilter || (!isFilter && status === 4) ? fromDate : '1/1/2020')
    // let to = new Date(toOptional ? fromOptional : isFilter || (!isFilter && status === 4) ? toDate : moment())
    let from = fromOptional ? fromOptional : fromDate
    let to = toOptional ? toOptional : toDate
    // let oneDay = 1000 * 60 * 60 * 24;
    if (moment(from) <= moment(to).add(1, 'days')) {

      if (checked) {

        apiReportsFilterPagination(status, status, from, to, reportKey, owner, dataType.map(i => i.idType).join(','), userOption, pageOpt ? pageOpt : page, pageSize, order.key, order.order, expiredOptional ? expiredOptional : statusReport, 2, dataUsers.map(i => i.idUser).join(',')).then(res => {
          settotalData(res.data.amount)
          setReports(res.data.reports)

        }).catch(err => {
          new Error(err)
        }).finally(() => {
          setloadingReports(false)
        })
      } else {

        apiReportsFilter(statusOptional, status, from, to, reportKey, owner, dataType.map(i => i.idType).join(','), userOption, dataUsers.map(i => i.idUser).join(','))
          .then((response) => {
            if (expiredOptional === 1) {
              response.data = response.data.filter(item => item.expired && item.status.id !== 4)
            } else if (expiredOptional === 0) {
              response.data = response.data.filter(item => !item.expired)
            }
            // setReports(response.data.filter(item => !item.reasonForDelete));
            setReports(response.data);
            if (find) {
              const findReport = response.data.find(report => `${report.id}` === find)
              if (findReport) {
                setreportSelected(findReport)
                setopenReport(true)
              } else {
                openMessage({ type: 'warning', message: 'No se ha podido encontrar el reporte' })
              }
            }
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            setloadingReports(false)
          })
      }


    } else {
      openMessage({ type: 'error', message: 'El rango de fechas no es correcto.' })
      setloadingReports(false)

    }

    t.stop()
  }, [owner, dataType, dataUsers, fromDate, toDate, userOption, reportKey, status, userOption, checked, page, pageSize, order.key, order.order, statusReport])

  const handleFilterToday = () => {
    setpage(1)
    setisFilter(true)
    handleFilter(moment().format('YYYY/MM/DD'), moment().format('YYYY/MM/DD'))
    setFromDate(moment().format('YYYY/MM/DD'))
    setToDate(moment().add(1, 'days').format('YYYY/MM/DD'))
  }

  const [openReport, setopenReport] = useState(false)
  const [reportSelected, setreportSelected] = useState()

  const closeDetails = () => {
    getDataReport(null, null, null, null, statusReport)
    setopenReport(false)
    setStatus(0)
    history.push(`/reportes`)
  }

  useEffect(() => {
    getDataReport(null, null, null, null, statusReport)

  }, [changeStatus, refresh, statusReport, update, checked])

  useEffect(() => {
    if (idStatusInt > 6 || idStatusInt < 0 || isNaN(idStatusInt)) {
      history.push('/reportes')
    } else {
      setStatus(idStatusInt)
      if (created) {
        handleClear()
        getDataReport(created)
      }
    }

  }, [idStatusInt, created])

  const { width } = useScreenSize()

  const handleRange = (event, newValue) => {
    setFromDate(moment(newValue[0]));
    setToDate(moment(newValue[1]));
    // getDataReport(null, null, newValue[0], newValue[1])
    // handleDateChanged()
  }

  const getDataReportDetail = () => {
    getDataReport(null, null, null, null, statusReport)
  }

  const handleClickMenu = ({ key }) => {
    setuserOption(key)

  }

  useEffect(() => {
    apiReportType()
      .then((response) => {
        response.data = response.data.filter(item => item.name !== 'CRM')
        response.data = response.data.map(item => {
          return { id: item.id, name: item.name, dependency: item.dependency.name }
        })
        setReportTypes(response.data);
      })
  }, [])

  const menu = (
    <Menu
      selectable
      selectedKeys={[userOption]}
      onClick={handleClickMenu}
      items={[
        {
          label: 'Creado por',
          key: CREATE_BY,
        },
        {
          label: 'Asignado por',
          key: ASSIGNED_BY,
        },
        {
          label: 'Encargado',
          key: IN_CHARGE,
        },
      ]}
    />
  );

  // const filterReportDates = () => {
  //   getDataReport(null, null, fromDate, toDate, null)
  //   setisFilter(true)
  //   setrefreshExcel(prev => !prev)
  // }

  const [users, setusers] = useState([])

  useEffect(() => {

    console.log(user)

    apiGetUsersByRole([2, 3, 5].join(',')).then(res => {
      setusers(res.data)
    }).catch(err => {
      new Error(err)
    })

  }, [])

  return (
    <div style={classes.container}>
      {
        (reportSelected && openReport) &&
        <Modal
          visible={openReport}
          onCancel={closeDetails}
          centered
          title={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {reportSelected.length !== 0 &&
                <Typography style={classes.dialogTitle} variant="h6">
                  {(!reportSelected.reasonForDelete && !reportSelected.deletedAt)
                    ?
                    <>
                      {reportSelected.status.id === 1 && 'Pendiente'}
                      {reportSelected.status.id === 2 && 'En proceso'}
                      {reportSelected.status.id === 3 && 'En revisión'}
                      {reportSelected.status.id === 4 && 'Solucionado'}
                    </>
                    : 'Por completar'
                  }
                </Typography>}
              <CloseCircleOutlined onClick={closeDetails} style={{ marginLeft: 'auto', color: 'red' }} />
            </div>
          }
          closable={false}
          footer={null}
        >
          <ReportDetails setRefresh={setRefresh} getDataReport={getDataReportDetail} data={reportSelected} onCloseDetails={closeDetails} setChangeStatus={setChangeStatus} changeStatus={changeStatus} />

        </Modal>
      }
      <div style={{ width: '100%' }}>

        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} >
          <Row style={{ width: '100%' }} gutter={[24, 24]}>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <Form.Item help={'Buscar por folio'}>
                <Input onChange={handleChangeKey} onPressEnter={handleSearch} value={reportKey} placeholder='Folio' size='middle' />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
              {/* <label style={{ marginTop: -18, fontSize: 12, marginLeft: 20 }}>Rango de Fechas</label> */}
              <div style={{ display: 'flex' }}>
                <Form.Item help='Rango de Fechas'>
                  <RangePicker
                    onChange={handleRange}
                    value={[moment(fromDate), moment(toDate)]}
                    locale={locale}
                    allowClear={false}
                    size='middle'
                    style={{ width: '100%', }}
                  />
                </Form.Item>
                {/* <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: 34 }} onClick={filterReportDates}>Filtrar</Button> */}
              </div>
              <Button type='link' onClick={handleFilterToday}>Reportes de hoy</Button>
            </Col>
            <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ marginLeft: 'auto' }}>
                {/* <SwitchReports value={false} onChange={handleChange} /> */}
                <SwitchMap checked={checked} handleChange={handleChange} />

              </div>

            </Col>
          </Row>
        </div>
        <Row style={{ ...classes.gridContainer, marginBottom: 10, marginTop: 20 }} gutter={[24, 24]}>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} style={{ marginBottom: 10, position: 'relative' }}>

            <Dropdown overlay={menu}>
              <Space style={{ overflow: 'hidden', position: 'absolute', top: -22, marginLeft: 12, opacity: .8, backgroundColor: '#FFF', border: '1px solid #e3e3e3', padding: '0px 10px 0px 10px', borderRadius: '10px 10px 0 0' }}>
                {userOption}
                <DownOutlined />
              </Space>
            </Dropdown>

            <Form.Item
              help={userOption === CREATE_BY ? 'Ingrese el nombre del ciudadano' : 'Seleccione los usuarios'}
            >
              {
                userOption === CREATE_BY
                  ? <Input placeholder='Nombre del ciudadano' onChange={handleUserChange} value={owner} size='middle' />
                  : <Select
                    size='middle'
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Usuarios"
                    onChange={handleChangeUser}
                    value={userName}
                    maxTagCount='responsive'
                  >
                    {users.map((user) => (
                      <Option title={`${user.name} ${user.lastName ? user.lastName : ''} ${user.email ? `(${user.email})` : ''}`} key={user.id} value={JSON.stringify(user)}>{user.name} {user.lastName ? user.lastName : ''} {user.email ? `(${user.email})` : ''}</Option>
                    ))}
                  </Select>
              }
              {/* <Select
                size='middle'
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Ciudadanos"
                onChange={handleChangeUser}
                value={userName}
                maxTagCount='responsive'
              >
                {users.map((user) => (
                  <Option title={`${user.name} ${user.lastName ? user.lastName : ''} ${user.email ? `(${user.email})` : ''}`} key={user.id} value={JSON.stringify(user)}>{user.name} {user.lastName ? user.lastName : ''} {user.email ? `(${user.email})` : ''}</Option>
                ))}
              </Select> */}
            </Form.Item>

          </Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} style={{ width: '100%' }} >
            <Form.Item
              help={userName && 'Seleccione los servicios'}
            >
              <Select
                size='middle'
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Servicios"
                onChange={handleChangeType}
                value={typeName}
                maxTagCount='responsive'
              >
                {reportTypes.map(i => ({ idType: i.id, name: i.name })).map((type) => (
                  <Option key={type.id} value={JSON.stringify(type)}>{`${type.name}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Button size='middle' onClick={handleFilter} type='default' >Buscar Reportes</Button>
              <Button size='middle' onClick={handleClear} type='link' >Limpiar filtros</Button>
            </div>
          </Col>
        </Row>
        {
          width > 900
            ? (
              <>

                <section style={{ width: '100%', marginBottom: 5, marginTop: -15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  {/* <Button style={{ ...classes.button, backgroundColor: status === 0 ? prMain : '', color: status === 0 ? '#FFF' : '#000' }} disabled={status === 0 || loadingReports} onClick={() => handleChangeStatus(0)} size="middle" variant="contained" elevation={3} >Todos</Button> */}
                  <Button style={{ ...classes.button, backgroundColor: status === 1 ? prMain : '', color: status === 1 ? '#FFF' : '#000' }} disabled={status === 1 || loadingReports} onClick={() => handleChangeStatus(1)} size="middle" variant="contained" elevation={3} >Pendientes</Button>
                  {
                    !isAllowed(user, ROLES_DIVISIONS.CAPTURIST) &&
                    <>
                      <Button style={{ ...classes.button, backgroundColor: status === 2 ? prMain : '', color: status === 2 ? '#FFF' : '#000' }} disabled={status === 2 || loadingReports} onClick={() => handleChangeStatus(2)} size="middle" variant="contained" elevation={3} >Asignados</Button>
                      <Button style={{ ...classes.button, backgroundColor: status === 3 ? prMain : '', color: status === 3 ? '#FFF' : '#000' }} disabled={status === 3 || loadingReports} onClick={() => handleChangeStatus(3)} size="middle" variant="contained" elevation={3} >Revisi&oacute;n</Button>
                      <Button style={{ ...classes.button, backgroundColor: status === 4 ? prMain : '', color: status === 4 ? '#FFF' : '#000' }} disabled={status === 4 || loadingReports} onClick={() => handleChangeStatus(4)} size="middle" variant="contained" elevation={3} >Solucionados</Button>

                    </>
                  }
                </section>
                <Col xs={12} style={{ marginBottom: 10 }} >
                  <Radio.Group disabled={loadingReports} value={statusReport} onChange={handleExpiredChange} optionType='default'>
                    <Radio value={5}>Todos</Radio>
                    <Radio value={1}>Expirados</Radio>
                    <Radio value={0}>Vigentes</Radio>
                  </Radio.Group>
                </Col>
              </>
            )
            : (
              <>
                <section style={{ width: '100%', marginBottom: 5, marginTop: -15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                  {/* <Tooltip title='Todos'>
                    <ContainerOutlined style={{ ...classes.button, fontSize: 20, backgroundColor: status === 0 ? prMain : '#FFF', color: status === 0 ? '#FFF' : prMain, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '5px 0px', borderRadius: 12 }} disabled={status === 0} onClick={() => loadingReports ? null : handleChangeStatus(0)} variant="contained" elevation={3} />
                  </Tooltip> */}
                  <Tooltip title='Pendientes'>
                    <FieldTimeOutlined style={{ ...classes.button, fontSize: 20, backgroundColor: status === 1 ? prMain : '#FFF', color: status === 1 ? '#FFF' : prMain, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '5px 0px', borderRadius: 12 }} disabled={status === 1} onClick={() => handleChangeStatus(1)} variant="contained" elevation={3} />
                  </Tooltip>
                  {
                    !isAllowed(user, ROLES_DIVISIONS.CAPTURIST) &&
                    <>
                      <Tooltip title='Asignados'>
                        <AuditOutlined style={{ ...classes.button, fontSize: 20, backgroundColor: status === 2 ? prMain : '#FFF', color: status === 2 ? '#FFF' : prMain, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '5px 0px', borderRadius: 12 }} disabled={status === 2} onClick={() => loadingReports ? null : handleChangeStatus(2)} variant="contained" elevation={3} />
                      </Tooltip>
                      <Tooltip title='Revisión'>
                        <IssuesCloseOutlined style={{ ...classes.button, fontSize: 20, backgroundColor: status === 3 ? prMain : '#FFF', color: status === 3 ? '#FFF' : prMain, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '5px 0px', borderRadius: 12 }} disabled={status === 3} onClick={() => loadingReports ? null : handleChangeStatus(3)} variant="contained" elevation={3} />
                      </Tooltip>
                      <Tooltip title='Solucionados'>
                        <CheckCircleOutlined style={{ ...classes.button, fontSize: 20, backgroundColor: status === 4 ? prMain : '#FFF', color: status === 4 ? '#FFF' : prMain, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', padding: '5px 0px', borderRadius: 12 }} disabled={status === 4} onClick={() => loadingReports ? null : handleChangeStatus(4)} variant="contained" elevation={3} />
                      </Tooltip>
                    </>
                  }
                </section>
                <Col xs={24} style={{ marginTop: 10, marginLeft: 10 }} >
                  <Radio.Group disabled={loadingReports} value={statusReport} onChange={handleExpiredChange} buttonStyle="solid">
                    <Radio value={5}>Todos</Radio>
                    <Radio value={1}>Expirados</Radio>
                    <Radio value={0}>Vigentes</Radio>
                  </Radio.Group>
                </Col>
              </>
            )
        }
      </div>
      <div style={{ width: '100%', height: '60vh', position: 'relative' }}>
        {
          checked
            ? (<>
              <DisplayedList
                owner={owner}
                statusReport={statusReport}
                refreshExcel={refreshExcel}
                isFilter={isFilter}
                setloadingReports={setloadingReports}
                setpage={setpage}
                page={page}
                setpageSize={setpageSize}
                order={order}
                setorder={setorder}
                totalData={totalData}
                from={fromDate}
                to={toDate}
                reportKey={reportKey}
                dataUsers={dataUsers}
                dataType={dataType}
                userOption={userOption} setReports={setReports} refreshReports={setRefresh} setRefresh={setRefresh} status={status} loadingReports={loadingReports} getDataReport={getDataReportDetail} data={reports} setChangeStatus={setChangeStatus} changeStatus={changeStatus} />
            </>
            )
            : (<>
              {/* <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#000', zIndex: loadingReports ? 99 : -1, opacity: loadingReports ? .2 : 0, display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '.5s' }}>
                <Spin size='middle' spinning={loadingReports} style={{opacity: 1}}/>
              </div> */}

              {
                loadingReports &&
                <div style={{ backgroundColor: prMain }} className="linear-activity">
                  <div className="indeterminate"></div>
                </div>
              }
              <MapContainer tap={false} id="map" center={position} zoom={zoom} scrollWheelZoom={true}>
                <ReactLeafletGoogleLayer apiKey={googleKey} type={'roadmap'} />

                <SetViewOnClick animateRef={true} />
                {reports.map((item, index) => (
                  <DraggableMarker refreshReports={setRefresh} setRefresh={setRefresh} key={index} getDataReport={getDataReportDetail} changeStatus={changeStatus} setChangeStatus={setChangeStatus} position={{ lat: item.latitude, lng: item.longitude }} setPosition={setPosition} dataRow={item} />

                ))}
              </MapContainer>
            </>)

        }
      </div>
    </div>
  );
}

export default DisplayedMap;