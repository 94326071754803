import { ControlOutlined } from '@ant-design/icons'
import { Button, Result, Switch, Typography, Input, Form } from 'antd'
import React, { useState } from 'react'
import { apiChangeStatusUser, apiActiveUser } from '../../../utils/api'

const classes = {
  button: {
      textTransform: 'capitalize',
      width: 'auto',
      fontSize: '15px',
      fontWeight: 'bold',
  },
  buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: 20,
  },
}

export const StatusUser = ({ data, onClose, setRefresh, refresh, isDone, setIsDone }) => {

  const statusDefault = data.user_status.id === 1 ? true : false
  const [status, setStatus] = useState(data.user_status.id === 1 ? true : false)
  const [success, setsuccess] = useState(false)
  const [success2, setsuccess2] = useState(false)
  const [error, seterror] = useState(false)
  const [error2, seterror2] = useState(false)
  const [loading, setloading] = useState(false)
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passDontMatch, setPassDontMatch] = useState(false)
  const [passLength, setPassLength] = useState(false)
  const [failed, setfailed] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')


  const handleChangeSwitch = (checked) => {
    setStatus(checked)
  }

  const handleSubmit = () => {
    setloading(true)
    apiChangeStatusUser(data.id, status ? 1 : 2)
      .then(res => {
        setloading(false)
        setsuccess(true)
        setIsDone(true)
      })
      .catch(err => {
        setloading(false)
        seterror(true)
        setIsDone(true)
      })
  }
  const handleConfirmPassword = () => {

    if (password !== confirmPassword) {
        setPassDontMatch(true);
        setPassLength(false);

    } else if (password.length < 8) {
        setPassLength(true);
        setPassDontMatch(false);

    } else {
        setPassDontMatch(false);
        setPassLength(false);
    }
}
  const handlePasswordChange = (event) => {
    setStatus(true)
    setPassword(event.target.value)
    setPhoneNumber(data.phone.toString())
  }

  const handleConfirmPasswordChange = (event) => {
    setfailed(false)
    setPassDontMatch(false);
    setPassLength(false);
    setConfirmPassword(event.target.value);
}

  const handleSetPassword = () => {
    setloading(true)
    setPassDontMatch(false);
    setPassLength(false);
    if (password !== confirmPassword) {
      setPassDontMatch(true);
      setPassLength(false);

    } else if (password.length < 8) {
      setPassLength(true);
      setPassDontMatch(false);

    } else {
      setPassDontMatch(false);
      setPassLength(false);
      apiActiveUser(phoneNumber, password)
        .then(res => {
        setloading(false)
        setsuccess2(true)
        setIsDone(true)
        handleSubmit()
      })
      .catch(err => {
        setloading(false)
        seterror(true)
        setIsDone(true)
      })
    }
  }

  return (
    console.log(data.user_status.id),
    (data.user_status.id == 1 || data.user_status.id == 2) ? 
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {
        !success && !error &&
        <>
          <Typography.Title level={4} style={{ marginBottom: 20 }} >Cambiar el estado del usuario {data.name} {data.lastName}</Typography.Title>
          <Switch style={{ marginBottom: 25, backgroundColor: '#23ba27' }} onChange={handleChangeSwitch} checked={status} checkedChildren="Activo" unCheckedChildren="Inactivo" />
          <Button disabled={statusDefault === status} type='primary' onClick={handleSubmit} loading={loading}> Cambiar estado </Button>
        </>
      }

      {
        success &&
        <Result
          status="success"
          title="Cambio realizado con éxito"
          subTitle="El estado del usuario ha sido cambiado con éxito"
          extra={[
            <Button key="console" type="primary" onClick={() => {
              setIsDone(!isDone)
              setRefresh(!refresh)
              onClose()
            }}>
              Aceptar
            </Button>,
          ]}
        />
      }
      {
        error &&
        <Result
          status="error"
          title="Error al cambiar el estado del usuario"
          subTitle="Por favor intente nuevamente"
          extra={[
            <Button key="back" type="primary" onClick={() => {
              setIsDone(!isDone)
              seterror(false)
            }}>
              Volver
            </Button>,
            <Button key="close" type="link" onClick={() => {
              setIsDone(!isDone)
              onClose()
            }}>
              Cerrar
            </Button>,
          ]}
        />
      }
    </div>
    : <div>
      {!success2 && !error2 &&
        <>
          <Typography.Title level={4} style={{ marginBottom: -15 }} >Establezca contraseña temporal para el usuario {data.name} {data.lastName}</Typography.Title>
          <Form.Item
            validateStatus={(passDontMatch || passLength || failed) ? 'error' : ''}
              help={
                failed
                ?'Ha ocurrido un error al intentar establecer la contraseña'
                : passDontMatch ? 'Las contraseñas no coinciden' 
                : passLength ? 'La contraseña debe tener al menos 8 caracteres' 
                : failed 
              }
          ></Form.Item>
          <Input.Password
            size='large'
            onChange={handlePasswordChange}
            value={password}
            placeholder="Ingrese contraseña"
            id="password"
            status={passLength || passDontMatch ? 'error' : ''}
          />
          <div style={{ marginTop: 20 }}>
            <Form.Item
              validateStatus={passDontMatch || passLength ? 'error' : ''}
              help={
                passDontMatch ? 'Las contraseñas no coinciden' : passLength ? 'La contraseña debe tener al menos 8 caracteres' : (!passDontMatch && !passLength)
              }
            >

              <Input.Password
                size="large"
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                placeholder="Confirme contraseña"
                id="password"
                status={passLength || passDontMatch ? 'error' : ''}
              />
            </Form.Item>
          </div>
          <div style={classes.buttons} >
            <Button loading={loading} style={classes.button} onClick={handleSetPassword} variant='contained' type='primary' size='large'>
              Guardar contraseña
            </Button>
          </div>
        </>
      }
      {
        success2 &&
        <Result
          status="success"
          title="La contraseña se guardó con éxito"
          subTitle="La contraseña del usuario ha sido establecida con éxito"
          extra={[
            <Button key="console" type="primary" onClick={() => {
              setIsDone(!isDone)
              setRefresh(!refresh)
              onClose()
            }}>
              Aceptar
            </Button>,
          ]}
        />
      }
      {
        error2 &&
        <Result
          status="error"
          title="Error al establecer la contraseña del usuario"
          subTitle="Por favor intente nuevamente"
          extra={[
            <Button key="back" type="primary" onClick={() => {
              setIsDone(!isDone)
              seterror(false)
            }}>
              Volver
            </Button>,
            <Button key="close" type="link" onClick={() => {
              setIsDone(!isDone)
              onClose()
            }}>
              Cerrar
            </Button>,
          ]}
        />
      }
    </div>
  )
}
