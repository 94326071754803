import { Button, Select } from 'antd';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { apiAsignReports, apiUsers } from '../../../../utils/api';
import { apiDependencies } from '../../../../utils/api';
import { apiReportType } from '../../../../utils/api';
import { openMessage } from '../../Utils/MessageDisplay';

export const ReportConfig = () => {

    const [value, setValue] = useState();
    const [dependency, setdependency] = useState(null)
    const [service, setservice] = useState([])
    const [users, setusers] = useState([])
    const [dependencys, setdependencys] = useState([])
    const [services, setservices] = useState([])
    const [loading, setloading] = useState({
        submit: false,
        users: false,
        dependencys: false,
        services: false
    })

    const handleChangeUser = (newValue) => {
        setValue(newValue);
    };
    const handleChangeDependency = (newValue) => {
        setdependency(newValue);
    };
    const handleChangeService = (newValue) => {
        setservice(newValue);
    };

    const handleSubmit = () => {
        console.log(service)
        if (!value || !dependency) return
        let data = { idUser: JSON.parse(value).id }

        if (service.length > 0) {
            data = { ...data, idServices: service.join(',') }
        } else {
            data = { ...data, idDependency: dependency }
        }
        setloading(prev => ({ ...prev, submit: true }))


        apiAsignReports(data).then(res => {

            setValue(null)
            setdependency(null)
            setservice([])
            openMessage({ type: 'success', message: `Reportes Asignados con Exito` })

        }).catch(err => {
            openMessage({ type: 'error', message: `Ha ocurrido un error al realizarr la petición, Intente nuevamente` })

        }).finally(() => {
            setloading(prev => ({ ...prev, submit: false }))

        })

    }

    useEffect(() => {
        if (!dependency) return

        setloading(prev => ({ ...prev, services: true }))
        setservice([])
        apiReportType().then(res => {
            res.data = res.data.filter(item => item.name !== 'CRM')

            res.data = res.data.filter(i => i.dependency.id === dependency)
            setservices(res.data.map(i => ({ value: i.id, label: i.name })))
            setloading(prev => ({ ...prev, services: false }))
        })

    }, [dependency])

    useEffect(() => {
        console.log(service)
        setloading(prev => ({ ...prev, users: true, dependencys: true }))
        apiUsers('/usuarios').then(res => {
            setusers(res.data.map(i => ({ value: JSON.stringify(i), label: `${i.name} ${i.lastName ? i.lastName : ''}` })))
            setloading(prev => ({ ...prev, users: false }))

        })

        apiDependencies().then(res => {
            setdependencys(res.data.map(i => ({ value: i.id, label: i.name })))
            setloading(prev => ({ ...prev, dependencys: false }))

        })
    }, [])

    return (
        <div>
            <div style={{ fontSize: '1.2rem' }}>Asignación de Reportes</div>
            <div style={{ opacity: 0.6 }}>
                Esta opción le permite asignar todos los reportes de una dependencia o servicios a un usuario
            </div>
            <section style={{ display: 'flex', flexDirection: 'column', gap: '1rem', margin: '1.5rem 0' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Seleccione el usuario</label>
                    <Select
                        loading={loading.users}
                        showSearch
                        value={value}
                        placeholder={'Usuario'}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption
                        onChange={handleChangeUser}
                        notFoundContent={null}
                        options={users}
                        style={{ width: '40%' }}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Seleccione la dependencia</label>
                    <Select
                        loading={loading.dependencys}
                        showSearch
                        value={dependency}
                        placeholder={'Dependencia'}
                        defaultActiveFirstOption={false}
                        filterOption
                        onChange={handleChangeDependency}
                        notFoundContent={null}
                        options={dependencys}
                        style={{ width: '40%' }}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Seleccione los servicios</label>
                    <Select
                        allowClear
                        loading={loading.services}
                        disabled={services.length === 0}
                        mode='multiple'
                        showSearch
                        value={service}
                        placeholder={'Servicios'}
                        onChange={handleChangeService}
                        options={services}
                        style={{ width: '40%' }}
                    />
                </div>
                <div style={{ opacity: 0.6 }}>
                    Si desea asignar todos los servicios de una dependencia deje el campo de servicios vacío
                </div>
            </section>

            <Button onClick={handleSubmit} loading={loading.submit} disabled={!value || !dependency} type='primary'>Asignar</Button>

        </div>
    )
}
