
import Background from './asserts/deliciasBackground.jpg'
import Logo from './asserts/logoDeliciasTouch2.svg'

export const webName = 'Delicias Touch'
export const contactEmail = 'contacto@deliciastouch.com'

export const prMain = '#FF9800E6';
export const prMainLight = '#FFC169';
export const prMainLighter = '#fff6ea';

export const background = Background
export const logo = Logo
