import React, { useEffect, useState } from 'react';
import MenuItemsProvider from './MenuItemsProvider';
import Profile from './form/Profile';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Notifications } from './notifications/Notifications';
import { useScreenSize } from '../hooks/useScreenSize';
import { prMain } from '../themeConfig';
import { Tooltip, Popover, List, Typography } from 'antd';
import { CloseOutlined, LeftOutlined, LogoutOutlined, RightOutlined, VerticalRightOutlined } from '@ant-design/icons';
import isAllowed, { ROLES_DIVISIONS } from '../layout/roles';
import './SideBar.css'
import moment from 'moment';

const Sidebar = (props) => {
  const [hidden, setHidden] = useState(false);
  const history = useHistory();
  // const notificationsForRead = props.notifications.filter(notification => notification.isRead === false).length
  const { user } = useSelector(state => state.session);
  const { width } = useScreenSize()

  const handleLogout = (event) => {
    sessionStorage.removeItem('user');
    window.location.replace('/login')
  }

  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);

  const handleClose = () => {
    setOpen(!open)
  }

  const handleNotifications = () => {
    setOpen(false)
    history.push('/notificaciones');
  }

  useEffect(() => {
    sessionStorage.setItem('drawerWidth', hidden ? 280 : 80);
  }, [hidden])

  useEffect(() => {
    if (width < 1350) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [width])

  return (
    <div
      style={{ ...classes.sidebar, width: hide ? 80 : 280, transitionDuration: '0.5s', transitionTimingFunction: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)', marginBottom: 30, borderBottomRightRadius: 20, borderBottomLeftRadius: 20, overflow: 'hidden', boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.25)' }}
    >
      <div style={{ width: '100%', zIndex: 99 }}>

        {
          (!hide && width > 800) &&
          <Tooltip placement='right' title='Comprimir menú'>
            <LeftOutlined onClick={() => setHide(true)} style={{ color: '#fff', border: '1px solid #FFF', float: 'right', borderRadius: 20, padding: 2, margin: 5, marginBottom: -10, cursor: 'pointer' }} />
          </Tooltip>
        }
        {
          (hide && width >= 800) &&
          <Tooltip placement='right' title='Expandir menú'>
            <RightOutlined onClick={() => setHide(false)} style={{ color: '#fff', border: '1px solid #FFF', float: 'right', borderRadius: 20, padding: 2, margin: 5, marginBottom: -10, cursor: 'pointer' }} />
          </Tooltip>
        }
        {
          (!props.hideSideBar && width < 800) &&
          <Tooltip placement='right' title='Ocultar menú'>
            <VerticalRightOutlined onClick={() => props.sethideSideBar(true)} style={{ color: '#fff',border: '1px solid #FFF', float: 'right', borderRadius: 20, padding: 2, margin: 5, marginBottom: -10, cursor: 'pointer' }} />
          </Tooltip>
        }
      </div>
      <div id="profile" style={classes.profileStyle} >
        <Profile hidden={hidden} setHidden={setHidden} hide={hide} />
      </div>
      <div id="menu" className='toolbar' style={{...classes.menu, height: '30vh', overflowY: 'auto'}}>
        <MenuItemsProvider hidden={hidden} openAction={props.onClose} hide={hide} />
      </div>
      <div id="footer" style={classes.footer}>
        { isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) &&
        //app version
        <div style = {{ color: '#ededed', fontSize: 16, textAlign: 'center' }}>
          v 1.1.1
        </div>}
        <hr color='white' />

        <div id="logout" style={{ position: 'relative' }}>
          <Popover
            placement='rightBottom'
            visible={open}
            onVisibleChange={() => setOpen(!open)}
            content={
              <div>
              <CloseOutlined onClick={handleClose} style={{float: 'right'}}/>
              <Notifications data={props.notifications} handleNotifications={handleNotifications} handleClose={handleClose} />
              </div>
            }
          >
            {/* {
              ((!isAllowed(user, ROLES_DIVISIONS.CAPTURIST) ) || (!isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)) || (!isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR)))  &&(
              hide
              ?(
                <Tooltip title='Notificaciones' placement='right'>
                <List.Item onClick={() => setOpen(true)}> 
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 22, cursor: 'pointer' }}>
                    <Badge count={notificationsForRead} size='small'>
                      <BellOutlined style={{ color: '#FFF', fontSize: 20 }} />
                    </Badge>
                  </div>
                </List.Item>
              </Tooltip>
              )
              :(
                <List.Item onClick={() => setOpen(true)}> 
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 22, cursor: 'pointer' }}>
                    <Badge count={notificationsForRead} size='small'>
                      <BellOutlined style={{ color: '#FFF', fontSize: 20 }} />
                    </Badge>
                      <Typography.Text style={{ color: '#fff', fontSize: 15, marginLeft: 20 }}>
                        Notificaciones
                      </Typography.Text >
                  </div>
                </List.Item>)
              )
            } */}
          
          </Popover>

          {
            hide 
            ?(
              <Tooltip title='Cerrar sesión' placement='right'>

              <List.Item onClick={handleLogout}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 25, cursor: 'pointer' }}>
                  <LogoutOutlined style={{ color: '#FFF', }} />
                </div>
              </List.Item>
            </Tooltip>
            )
            :(
              <List.Item onClick={handleLogout}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 25, cursor: 'pointer' }}>
                  <LogoutOutlined style={{ color: '#FFF', }} />
                    <Typography.Text style={{ color: '#fff', fontSize: 15, marginLeft: 20 }}>
                      Cerrar sesión
                    </Typography.Text>
                </div>
              </List.Item>
            )
          }
        </div>
        <hr color='white' />
        <div id="declaimer" style={classes.declaimer}>
          MVP Technology Solutions &copy; {moment().format('YYYY')}.
        </div>
      </div>
    </div>
  );
}

const classes = {
  declaimer: {
    textAlign: 'center',
    fontSize: '10px',
  },
  menu: {
    minHeight: '300px',
    maxHeight: '76vh',
    flexGrow: 1,
    margin: 5,
    color: '#FFF'
  },
  icon: {
    marginRight: 10
  },
  profileStyle: {
    minHeight: '90px',
    height: '9vh',
    fontWeight: "bold",
  },
  footer: {
    gridArea: 'footer',
    flexShrink: 1,
    color: '#FFF',
    margin: 5
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'static',
    background: prMain,
  },
}

export default Sidebar;