import './App.less'
import { Switch, Route } from 'react-router-dom';
import MainPage from './components/pages/MainPage';
import LoginPage from './components/pages/LoginPage';
import SetPassword from './components/pages/SetPassword';
import SocketController from './SocketController';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingPage } from './utils/LoadingPage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseNotifications } from './FirebaseNotifications';
import { ChangeEmail } from './components/pages/changeData/ChangeEmail';
import { ChangePhone } from './components/pages/changeData/ChangePhone';
import SupportPage from './components/pages/Support/Support';
import SetFirstPassword from './components/pages/SetFirstPassword';
import { Register } from './components/pages/Register/Register';
import VotesPage from './components/pages/votes/VotesPage';
import { apiGetConfiguration } from './utils/api';
// import VotesPage from './components/pages/votes/VotesPage';

const App = () => {

  const { user, token } = useSelector(state => state.session)
  let flag = undefined;

  const isLoggedIn = (token) => {
    if (token === null || token === undefined) {
      return flag = false
    } else {
      return flag = true
    }
  }

  const [loading, setLoading] = useState(true)
  const [voteStatus, setvoteStatus] = useState(true)

  useEffect(() => {
    apiGetConfiguration('Vote_Budget').then(res => {
      setvoteStatus(res.data.active)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
    setLoading(false)

  }, [])

  isLoggedIn(token)

  const analytics = getAnalytics();
  logEvent(analytics, 'screen_view', {
    firebase_screen: window.location.pathname,
  });

  return (
    <div>
      <SocketController />
      <FirebaseNotifications />
      {
        loading &&
        // <BackdropLoading open= { loading } />
        <LoadingPage />
      }
      {
        !loading &&
        <Switch>
          <Route path='/s/:code?' component={SetPassword} />
          <Route path='/setPassword/:code?' component={SetPassword} />
          <Route path="/support" component={SupportPage} />
          <Route path='/g/:code?' component={SetPassword} />
          <Route path='/getPassword/:code?' component={SetPassword} />
          <Route path='/retorePassword/:code?' component={SetPassword} />
          <Route path='/r/:code?' component={SetPassword} />
          <Route path='/changeEmail/:code?' component={ChangeEmail} />
          <Route path='/e/:code?' component={ChangeEmail} />
          <Route path='/changePhone/:code?' component={ChangePhone} />
          <Route path='/p/:code?' component={ChangePhone} />
          <Route path='/register' component={Register} />
          {
            voteStatus &&
            <Route path='/votos' component={VotesPage} />
          }
          {flag === false && <Route path="/" > <LoginPage status={voteStatus} /> </Route>}
          {flag === true && <Route path="/" component={user.changePassword ? SetFirstPassword : MainPage} />}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      }
    </div>
  );
}

export default App;
